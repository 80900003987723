import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "https://m.m555m.com/";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const BTN_LOGIN_URL = "https://m.m555m.com/";
export const BTN_REGISTER_URL = "https://m.m555m.com/?action=register";

export const DOMAINS_USE_STATIC_URL = [
  "moo268.com",
  "moo555268.com",
  "moo555555.com",
  "moo5555555.com",
  "moo55555555.com",
  "moo666.com",
  "moo9898.com",
];

export * from "./codes";
export * from "./context";

export const DEFAULT_ENTER_SITE_URL = "https://m.m555m.com/";

export const ENTER_SITE_URL_MAP = {
  "moo5555.me": "https://m.m555m.com/",
  "m5oo.com": "https://m.m555mo.com/",
  "m55ok.com": "https://m.m555m.com/",
  "moo55m.com": "https://m.m555m.com/",

  "m555moo.com": "https://m.m555m555.com",
  "m555oo.com": "https://m.m555m555.com",
  "555moo.com": "https://m.m555m555.com",
};
