import { Dialog, DialogContent, DialogProps, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "src/main/constants";
import { createStyles } from "src/main/utils";

interface DebugDialogProps extends Omit<DialogProps, "open"> {}

const DebugDialog: React.FC<DebugDialogProps> = (props) => {
  const { ...rest } = props;
  const { urls, isFbIab, isLineIab, isFullScreen, isIosDevice } = useAppContext();
  const [params, setSearchParams] = useSearchParams();

  return (
    <Dialog
      open={params.has("debug")}
      sx={styles.root}
      onClose={() => {
        setSearchParams((prev) => {
          prev.delete("debug");
          return prev;
        });
      }}
      {...rest}
    >
      <DialogContent>
        <Typography variant="body1">Register: {urls?.registerUrl}</Typography>
        <Typography variant="body1">Login: {urls?.loginUrl}</Typography>
        <Typography variant="body1">FB IAB: {isFbIab ? "yes" : "no"}</Typography>
        <Typography variant="body1">Line IAB: {isLineIab ? "yes" : "no"}</Typography>
        <Typography variant="body1">iOS Device: {isIosDevice ? "yes" : "no"}</Typography>
        <Typography variant="body1">iOS Fullscreen: {isFullScreen ? "yes" : "no"}</Typography>
        <Typography variant="body1">User Agent: {navigator.userAgent}</Typography>
        <Typography variant="body1">Platform: {navigator.platform}</Typography>
      </DialogContent>
    </Dialog>
  );
};

const styles = createStyles({
  root: {},
  image: {
    img: {
      width: "100%",
      height: "auto",
      borderRadius: 1,
    },
  },
});

export default DebugDialog;
