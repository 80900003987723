export const FallbackRefCode = ["jDro6zP6cM", undefined] as const satisfies readonly [string, string | undefined];

export const RefCodeMap: {
  [key: string]: [string] | [string, string];
} = {
  "moowin99.com": ["f5aTPUYCaO"],
  "moo17888.com": ["HVzvFJaNxs"],
  "moo777788.com": ["mjxGwObZrF"],
  "moomoo1888.com": ["pEK56rHHpK"],
  "moola888.com": ["p6JCn2cWg3"],
  "moola77.com": ["RWwZ8d8W7q"],
  "moomoo55.com": ["8mc9LNCBT0"],
  "moola777.com": ["0HKQA5i5wQ"],
  "moo1221.com": ["wfiN7X0s8A"],
  "moo98.com": ["qCssWMXOzE"],
  "moo198.com": ["2JZPvZ5aDm"],
  "moo68.com": ["rwVEQpnJv2"],
  "moo668.com": ["Uu6aPt0HPP"],
  "moomoo8.com": ["9lgmaJEhlY"],
  "moo2565.com": ["nDP7WLt8OX"],
  "moo77.com": ["nlyAkzZPC6"],
  "mooxqc.com": ["jbBNuqAO4d"],
  "moo7777.com": ["VyBoLeuBFQ"],
  "moolol55.com": ["eukcsYMg0Y", "299468016099753"],
  "moola8.com": ["dskhMWI1ws", "262590719539631"],
  "moozzz88.com": ["ujXt4lT2cK", "296099446309456"],
  "mooo168.com": ["KgvpYHKgpl", "1503703910406657"],
  "moo9698.com": ["hF2QWKeGxU", "271854799026447"],
  "moowin998.com": ["xO6lHnhXJ5", "1365678397662479"],
  "mooxqc8.com": ["89P0WkU4nC", "215814284582588"],
  "moo1788.com": ["4ZjFhs0zPM", "6401384239960589"],
  "moo77778.com": ["ED7fEc2pmz", "697276938437414"],
  "moomoo188.com": ["rKIXJBXR27", "309207225037478"],
  "moolol558.com": ["sH5VlvPaaV", "695967058582206"],
  "moola88.com": ["0WS54P8EDB", "150208901481992"],
  "moo55555.com": ["ptS71QukbM"],
  "moo5555.com": ["wSoWjpKPun"],
  "moo989.com": ["teGO2aRTHB", "1528731221203180"],
  "moo898.com": ["fc1IDTFyYh", "718805630099316"],
  "moo988.com": ["zGw0HjDmbE", "1761624174309042"],
  "moo929.com": ["AGI8KEeDXU", "340042168590887"],
  "moo292.com": ["4K5Bys2M3V", "1482109569292804"],
  "moo229.com": ["qXJhctZQB2", "301260682659256"],
  "moo922.com": ["tv7WvvwUkq", "624532002925115"],
  "moo22.com": ["Sqh1zc62UY", "1958969061162827"],
  "moo669.com": ["V4ix0qlff5", "790399282775261"],
  "moo882.com": ["SJMkXR1T8w", "186307491156136"],
  "moo282.com": ["q6vsgHEDrT", "1023622378680461"],
  "moo228.com": ["ZGYNuxjjVL", "335579925610382"],
  "moo822.com": ["RIgbWUErQJ", "772103554604338"],
  "moo828.com": ["LSmeJBH6i9", "942713167416037"],
  "moo333.com": ["0418q4PrEH", "1393096688219104"],
};
